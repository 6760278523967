<template>
  <div class="team">
    <v-row class="mb-5" v-if="account_type === 'manager'">
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ numberWithComma(statistic.salary ? statistic.salary : 0) + statistic.currencySymbol }}
              </h2>
              <span>المبلغ الكلي</span>
            </div>

            <v-icon size="30" color="primary" class="rounded-0">
              {{ icons.mdiCashMultiple }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ numberWithComma(statistic.payment ? statistic.payment : 0) + statistic.currencySymbol }}
              </h2>
              <span>المدفوعات</span>
            </div>

            <v-icon size="30" color="secondary" class="rounded-0"> fa-coins </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ numberWithComma(statistic.discount ? statistic.discount : 0) + statistic.currencySymbol }}
              </h2>
              <span>الخصم</span>
            </div>

            <v-icon size="30" color="warning" class="rounded-0"> fa-cash-register </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ numberWithComma(statistic.remaining ? statistic.remaining : 0) + statistic.currencySymbol }}
              </h2>
              <span>المتبقي</span>
            </div>

            <v-icon size="40" color="error" class="rounded-0">
              {{ icons.mdiCash }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center mb-3 subtitle-4 black--text">فواتير اخرى</h1>
      <h3 class="text-center mb-3 subtitle-5 black--text">{{ $route.params.name }}</h3>
      <v-row>
        <v-col md="3" sm="3" cols="12" align-self="center">
          <v-btn tile color="primary" class="ml-2 mb-4 mb-md-0 mb-sm-0" @click="addDialog.open = true">
            اضافة فاتورة
            <v-icon right> fa-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2" cols="12">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tableModifier.start_date"
                dense
                label="من"
                outlined
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="tableModifier.start_date" @input="menuStartDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2" cols="12">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tableModifier.end_date"
                dense
                label="الى"
                outlined
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="tableModifier.end_date" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="3" sm="3" cols="12">
          <v-text-field v-model="table.search" label="البحث" append-icon="mdi-magnify" single-line hide-details>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="table.loading"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :headers="headers"
            :items="driversData"
            :search="table.search"
            :items-per-page="10"
            item-key="class_school_id"
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
            }"
          >
            <template slot="item._id" slot-scope="props"> {{ props.index + 1 }} </template>
            <template v-slot:item.service_name="{ item }">
              <a @click="showBillsDetails(item._id, item.service_name)">{{ item.service_name }}</a>
            </template>
            <template v-slot:item.salaryAmount="{ item }">
              {{ numberWithComma(item.salaryAmount) }}
            </template>
            <template v-slot:item.paymentAmount="{ item }">
              {{ numberWithComma(item.paymentAmount) }}
            </template>
            <template v-slot:item.discountAmount="{ item }">
              {{ numberWithComma(item.discountAmount) }}
            </template>
            <template v-slot:item.remainingAll="{ item }">
              {{ numberWithComma(item.remainingAll) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="editItem(item)">
                      fa-edit </v-icon>
                  </template>
                  <span>تعديل</span>
                </v-tooltip> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FF8A80" class="ml-2" v-bind="attrs" size="18" v-on="on" @click="deleteItem(item)">
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FDD835" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="printPage(item)">
                    fa-print
                  </v-icon>
                </template>
                <span>طباعة</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="secondary"
                    class="ml-2"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="editItemPayment(item)"
                  >
                    fa-dollar-sign
                  </v-icon>
                </template>
                <span>اضافة عملية</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="#1DE9B6"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="showBillsDetails(item._id, item.service_name)"
                  >
                    fa-table
                  </v-icon>
                </template>
                <span>عرض الفواتير</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <!-- add dialog -->
    <v-dialog v-model="addDialog.open" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialog.isFormValidAdd">
              <v-row no-gutters>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="addData.service_type"
                    :rules="rules.service_type"
                    :loading="serviceLoading"
                    :items="servicesData"
                    item-text="name"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                    label="نوع الخدمة"
                    @click:clear="addData.service_type = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="addData.salaryAmount"
                    label="المبلغ"
                    :rules="rules.salaryAmount"
                    outlined
                    required
                    @keypress="isNumber($event)"
                    @change="salaryPlusDiscountAmountChange"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="addData.discountAmount"
                    label="الخصم"
                    outlined
                    required
                    @keypress="isNumber($event)"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="addData.salaryPlusDiscountAmount"
                    label="المبلغ الكلي بعد الخصم"
                    outlined
                    disabled
                    filled
                    required
                    @keypress="isNumber($event)"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="addData.paymentAmount"
                    label="المبلغ المدفوع"
                    outlined
                    required
                    @keypress="isNumber($event)"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="addData.remainingAmount"
                    label="المبلغ المتبقي"
                    outlined
                    required
                    @keypress="isNumber($event)"
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="menuNextPayment"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="addData.next_payment"
                        :disabled="nextPaymentDisable"
                        dense
                        label="تاريخ الدفعة القادمة"
                        outlined
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="addData.next_payment" @input="menuNextPayment = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="addData.date"
                        dense
                        :rules="rules.date"
                        label="تاريخ انشاء الفاتورة"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="addData.date" @input="menuDate = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="addData.invoice_number"
                    label="رقم الوصل"
                    outlined
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined rows="2" row-height="10" v-model="addData.desc" label="الملاحظة"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialog.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="addDialog.loading"
            :disabled="!addDialog.isFormValidAdd"
            @click="addStudentBillsData"
          >
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add dialog Payment-->
    <v-dialog v-model="addDialogPayment.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialogPayment.isFormValidAdd">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="payments.type"
                    :items="paymentsType"
                    dense
                    label="اختيار نوع العملية"
                    outlined
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="payments.amount"
                    dense
                    @keypress="isNumber($event)"
                    label="المبلغ"
                    :rules="rulesPayment.amount"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="editedItemInvoice.remainingAll" dense disabled label="المتبقي" outlined>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-menu
                    v-model="menuNextPayment"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="payments.next_payment"
                        dense
                        label="تاريخ الدفعة القادمة"
                        outlined
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="payments.next_payment" @input="menuNextPayment = false"> </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="addData.invoice_number" label="رقم الوصل" outlined required dense></v-text-field>
              </v-col>
              <v-row>
                <v-col cols="12">
                  <v-textarea outlined rows="4" row-height="20" v-model="payments.desc" label="الملاحظة"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialogPayment.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="addDialogPayment.loading"
            :disabled="!addDialogPayment.isFormValidAdd"
            @click="editItemConformPayment"
          >
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit Class dialog -->
    <v-dialog v-model="dialogEdit.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogEdit.isFormValid">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="editedItem.name" label="الاسم" :rules="rules.name" outlined></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="dialogEdit.loading"
            :disabled="!dialogEdit.isFormValid"
            @click="editItemConform"
          >
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit Class dialog -->
    <!--- Dialog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا الحساب ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from '@/api/api'
import { getCurrentDateInString } from '@/constant/date'
import numberWithComma from '@/constant/number'
import { mdiCash, mdiCashMultiple } from '@mdi/js'

export default {
  data () {
    return {
      account_type: null,

      menuStartDate: false,

      menuEndDate: false,

      tableModifier: {
        start_date: null,
        end_date: null,
      },

      icons: {
        mdiCashMultiple,
        mdiCash,
      },

      servicesData: [],

      serviceLoading: false,

      studentsData: [],

      studentLoading: false,

      nextPaymentDisable: false,

      menuDate: false,

      editedItemInvoice: {},

      menuNextPayment: false,

      payments: {
        name: null,
        amount: null,
        type: 'payment',
        next_payment: null,
        study_year: null,
        desc: null,
      },

      rulesPayment: {
        name: [value => !!value || 'الاسم مطلوب'],
        amount: [value => !!value || 'المبلغ مطلوب'],
        checkAmount: [value => value > 70 || 'noo'],
      },

      paymentsType: [
        { text: 'تسديد', value: 'payment' },
        { text: 'خصم', value: 'discount' },
      ],

      addDialogPayment: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false,
      },

      addDialog: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false,
      },

      addData: {
        name: null,
        salaryAmount: null,
        paymentAmount: null,
        discountAmount: null,
        remainingAmount: null,
        next_payment: null,
        desc: null,
        invoice_number: null,
        service_type: null,
        salaryPlusDiscountAmount: null,
        date: getCurrentDateInString(),
      },

      deleteItemLoading: false,

      dialogDelete: false,

      rules: {
        name: [value => !!value || 'الاسم مطلوب'],
      },

      editedItem: {},

      class_data: {
        selectedClass: null,
        class_school_leader: null,
      },

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      table: {
        loading: false,
        search: null,
      },

      dialogEdit: {
        open: false,
        isFormValid: false,
        loading: false,
      },

      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: '_id',
        },
        { text: 'نوع الخدمة', sortable: false, value: 'service_name' },
        { text: 'تاريخ انشاء الفاتورة', sortable: false, value: 'date' },
        { text: 'المبلغ الكلي', sortable: false, value: 'salaryAmount' },
        { text: 'المبلغ المدفوع', sortable: false, value: 'paymentAmount' },
        { text: 'الخصم', sortable: false, value: 'discountAmount' },
        { text: 'المتبقي', sortable: false, value: 'remainingAll' },
        { text: 'العملة', sortable: false, value: 'currencySymbol' },
        { text: 'تاريخ الدفعة القادمة', sortable: false, value: 'nextPaymentDate' },
        { text: 'رقم الوصل', sortable: false, value: 'invoice_number' },
        { text: 'الملاحظات', sortable: false, value: 'desc' },
        { text: 'العمليات', value: 'actions', sortable: false },
      ],
      driversData: [],
      deleteItemLoading: false,
      dialogDelete: false,

      statistic: {
        currency: 'IQD',
        currencySymbol: ' IQD',
        discount: null,
        payment: null,
        salary: null,
        remaining: null,
      },
    }
  },

  watch: {
    'addData.salaryAmount': {
      handler () {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },
    'addData.paymentAmount': {
      handler () {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },
    'addData.discountAmount': {
      handler () {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },

    'addData.remainingAmount': {
      handler () {
        if (this.addData.remainingAmount <= 0) {
          this.nextPaymentDisable = true
          this.addData.next_payment = null
        } else {
          this.nextPaymentDisable = false
        }
      },
      // deep: true,
    },
    'tableModifier.start_date': {
      handler () {
        this.getData()
      },
      // deep: true,
    },

    'tableModifier.end_date': {
      handler () {
        this.getData()
      },
      // deep: true,
    },
  },

  mounted () {
    this.getData()
    this.getStudents()
    this.getServices()
    this.account_type = JSON.parse(localStorage.getItem('results')).account_type
  },

  methods: {
    async getData () {
      this.table.loading = true
      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getOneOtherBills({
        isDeleted: false,
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
        isPaid: null,
        study_year,
        name: this.$route.params.name,
      })

      this.statistic = response.data.statistic

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.table.loading = false
        this.driversData = response.data.results
      }
    },

    async getStudents () {
      this.studentLoading = true

      const response = await Api.getAllStudents()

      if (response.status === 401) {
        this.studentLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.studentLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.studentLoading = false
        this.studentsData = response.data.results
      }
    },

    async getServices () {
      this.serviceLoading = true

      const response = await Api.getService('واردات اخرى')

      if (response.status === 401) {
        this.serviceLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.serviceLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.serviceLoading = false
        this.servicesData = response.data.results
      }
    },

    async addServiceData () {
      this.addDialog.loading = true

      const response = await Api.addService(this.addData.name, this.$route.params.type)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.getData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    salaryPlusDiscountAmountChange () {
      this.addData.salaryPlusDiscountAmount =
        (this.addData.salaryAmount ? this.addData.salaryAmount : 0) -
        (this.addData.discountAmount ? this.addData.discountAmount : 0)
      this.addData.salaryPlusDiscountAmount ? this.addData.salaryPlusDiscountAmount : 0
    },

    paymentChange () {
      this.addData.remainingAmount =
        (this.addData.salaryPlusDiscountAmount ? this.addData.salaryPlusDiscountAmount : 0) -
        (this.addData.paymentAmount ? this.addData.paymentAmount : 0)
      this.addData.remainingAmount ? this.addData.remainingAmount : 0
    },

    async addStudentBillsData () {
      let payments = []
      const study_year = JSON.parse(localStorage.getItem('study_year'))
      if (this.addData.salaryAmount) {
        payments.push({
          amount: this.addData.salaryAmount,
          type: 'salary',
          study_year: study_year,
          next_payment: this.addData.next_payment,
          desc: this.addData.desc,
        })
      }

      if (this.addData.paymentAmount) {
        payments.push({
          amount: this.addData.paymentAmount,
          type: 'payment',
          study_year: study_year,
          next_payment: this.addData.next_payment,
          desc: this.addData.desc,
        })
      }

      if (this.addData.discountAmount) {
        payments.push({
          amount: this.addData.discountAmount,
          type: 'discount',
          study_year: study_year,
          next_payment: this.addData.next_payment,
          desc: this.addData.desc,
        })
      }
      this.addDialog.loading = true

      const response = await Api.addOtherBills({
        name: this.$route.params.name.trim(),
        date: this.addData.date,
        service_type: this.addData.service_type,
        desc: this.addData.desc,
        invoice_number: this.addData.invoice_number,
        payments,
      })

      if (response.status === 401) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.getData()
      }
    },

    showBillsDetails (id, name) {
      this.$router.push(
        `/anotherBills/showBills/customer_name/${name}/name/${this.$route.params.name}/showDetails/id/${id}`,
      )
    },

    deleteItem (item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      this.deleteItemLoading = true

      const response = await Api.removeOneOtherBills(this.deletedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    editItemPayment (item) {
      this.editedItemInvoice = { ...item }
      this.addDialogPayment.open = true
    },

    async editItemConformPayment () {
      if (this.editedItemInvoice.remainingAll < this.payments.amount) {
        this.showDialogfunction('لا يمكن تسديد مبلغ اكثر من المتبقي', '#FF5252')
      } else {
        this.addDialogPayment.loading = true

        const study_year = JSON.parse(localStorage.getItem('study_year'))
        this.payments.study_year = study_year

        const response = await Api.addAnotherPayment(this.payments, this.editedItemInvoice._id)

        if (response.status === 401) {
          this.$store.dispatch('submitLogout')
        } else if (response.status === 500) {
          this.addDialogPayment.open = false
          this.addDialogPayment.loading = false

          this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
        } else {
          this.addDialogPayment.open = false
          this.addDialogPayment.loading = false
          this.getData()
          this.showDialogfunction(response.data.results, 'primary')
        }
      }
    },

    editItem (item) {
      this.editedItem = { ...item }
      this.dialogEdit.open = true
    },

    async editItemConform () {
      this.dialogEdit.loading = true

      const response = await Api.editService(this.editedItem.name, this.editedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false

        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.getData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    getDateString (isoDate) {
      let date = new Date(isoDate)
      const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]

      return dateString
    },

    showDialogfunction (bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    printPage (item) {
      let routeData = this.$router.resolve({ name: 'anotherBillDetailsInvoice' })

      window.open(routeData.href, '_blank')

      localStorage.setItem('anotherBillDetailsInvoice', JSON.stringify(item))

      // router.push('studentBillInvoice')
    },

    isNumber (evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    numberWithComma,
  },
}
</script>

<style>
.teacher_image_table {
  cursor: pointer;
}
</style>

<style scoped>
a {
  text-decoration: none;
}
</style>
